export const environment = {
    production: true,
    staging: false,
    customer_id: '86',
    restApiUrl: 'https://rc.api.client.5ways.com/rest/',
    apiUrl: 'https://rc.api.client.5ways.com/',
    rootUrl: 'https://rc.api.client.5ways.com/',
    apiClientId: '9bd30c170b589eb18a03ce19106de6e2',
    socketsUrl: 'https://rc.ws.5ways.com',
    iframeParentDomain: 'https://wfirma.pl',
    testLogin: '',
    testPassword: '',
    devDebug: false
};
// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.
